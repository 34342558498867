<template>
  <v-container class="view-credential">
    <v-row>
      <v-col cols="12" class="pt-7 pb-0 breadcrumbs">
        <div @click="$router.push('/Dashboard')">
          <p style="margin: 2px">
            {{ $t('dashboard.title') }}
          </p>
        </div>
        <div
          @click="currentPage && $router.go(-1)"
          :class="{ active: !currentPage }"
        >
          <IconArrowCreadCrumbs style="margin: 0px 6px 2px 6px" />
          <p style="margin: 2px 6px 2px 2px">
            {{ templateName }}
          </p>
        </div>
        <div v-if="currentPage" :class="{ active: currentPage }">
          <IconArrowCreadCrumbs style="margin: 0px 6px 2px 6px" />
          <p style="margin: 2px 6px 2px 2px">
            {{ currentPage }}
          </p>
        </div>
      </v-col>
      <v-col cols="12" class="pt-2">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IconArrowCreadCrumbs from '@/assets/icon-arrow-bread-crumbs.vue';
import { GET_TEMPLATE } from '../store/actions';
import { mapGetters } from 'vuex';
export default {
  name: 'ViewTemplateIndex',
  components: {
    IconArrowCreadCrumbs,
  },
  computed: {
    currentPage() {
      let option = this.$route.name;
      if (this.$te('issue.' + option)) {
        return this.$t('issue.' + option);
      }
      return null;
    },
    ...mapGetters(['cid']),
  },
  watch: {
    cid(value) {
      if (value) {
        this.getTemplate();
      }
    },
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`);
  },
  mounted() {
    console.log(this.$route);
    if (this.cid) {
      this.getTemplate();
    }
  },
  methods: {
    async getTemplate() {
      if (this.$route.params && this.$route.params.tid) {
        this.debug('Call GET_TEMPLATE action');
        let template = await this.$store.dispatch(GET_TEMPLATE, {
          tid: this.$route.params.tid,
        });
        if (template) {
          this.templateName = template.name;
          await this.$store.dispatch('template/SET_TEMPLATE', {
            template,
          });
        }
      }
    },
  },
  data() {
    return {
      templateName: null,
    };
  },
};
</script>

<style></style>
